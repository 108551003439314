import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-support-dialog',
  templateUrl: './support-dialog.component.html',
  styleUrls: ['./support-dialog.component.scss']
})
export class SupportDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<SupportDialogComponent>,
  ) {

  }

  close() {
    this.dialogRef.close();
  }
}
