import {Injectable} from '@angular/core';
import {combineLatest, from, Observable, of} from 'rxjs';
import {filter, map, switchMap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {UtilsService} from '../../shared/services/utils.service';
import {AccountService} from '../../accounts/account.service';
import {SecuritiesService} from '../../securities/securities.service';
import {Account} from '../../accounts/account';
import {Security} from '../../securities/security';
import _uniq from 'lodash-es/uniq';
import {ManualHoldingsService} from '../../manual-holdings/manual-holdings.service';

@Injectable({
  providedIn: 'root'
})
export class FirmWideReportingService {

  transactions = [];
  balances = [];

  baseEndpoint = `${environment.apiV2Url}/data/luca`;
  sourceEndpoint = `${environment.apiV2Url}/data/source`

  constructor(private httpClient: HttpClient,
              private utilsService: UtilsService,
              private accountService: AccountService,
              private securitiesService: SecuritiesService,) {
  }

  private getDSTAndManualAccountIDs(): Observable<number[]> {
    return this.getDSTAndManualAccounts().pipe(
      map((accounts: Account[]) => {
        const accountIDs = accounts.map(a => a.id);

        accounts.forEach(a => {
          if (a.custodian === 'HDG' || a.custodian === 'DST') {
            accountIDs.push(a.id);
          }
        })

        return accountIDs;
      })
    );
  }

  private getDSTAndManualAccounts(): Observable<Account[]> {
    const endpoint = `${environment.apiV2Url}/account-management/accounts/filter`;

    const filter = {
      custodian: {
        any_or_all: 'any',
        conditions: [{
          value: 'HDG',
          op: 'eq'
        }, {
          value: 'DST',
          op: 'eq',
        }]
      }
    }

    return this.httpClient.post(endpoint, filter).pipe(
      map((resp: any) => {
        return resp.data;
      })
    );
  }

  // needed for Manual Accounts and DST accounts (HDG and DST custodian codes)
  fetchLucaAccountBalancesReport(startDate: string, endDate: string) {
    const endpoint = `${this.baseEndpoint}/account-balances/filter`;

    return this.getDSTAndManualAccounts().pipe(
      switchMap((accounts: Account[]) => {
        if (!accounts.length) {
          return of([[], []])
        }

        const accountIDs = accounts.map(a => a.id);

        const filter = this.buildManualAccountsFilter(accountIDs, startDate, endDate)

        return combineLatest([of(accounts), this.utilsService.fetchAllFilteredPages(endpoint, filter, 15000)])
      }),
      map(([accounts, balances])=> {
        const accountMap = {};

        accounts.forEach(a => {
          accountMap[a.id] = a;
        })

        return balances.map(b => {
          const account = accountMap[b.account_id];

          if (!account) {
            return;
          }

          this.enrichAccountData(b, account);

          b.securities_value = b.security_holdings_value;
          b.total_value = b.cash_value + b.security_holdings_value;
          return b;
        }).filter(d => d !== undefined);
      })
    );
  }

  private fetchSourceBalancesReport(startDate: string, endDate: string) {
    const endpoint = `${this.sourceEndpoint}/account-balances/filter`;
    const dateFilter = this.getReportedDateFilter(startDate, endDate);

    return this.accountService.entitiesLoaded$.pipe(
      filter(loaded => loaded),
      switchMap((loaded) => {
        return this.utilsService.fetchAllFilteredPages(endpoint, dateFilter, 15000)
      }),
      map((balances ) => {
        const accountMap = this.accountService.accountsByAccountId;

        return balances.map(b => {
          // TODO: Compare with a .find()
          const account = accountMap[b.account_id];

          if (!account) {
            return;
          }

          // Take reported balance values over actual values if applicable.
          if (b.cash_value_reported) {
            b.cash_value = b.cash_value_reported;
          }

          if (b.securities_value_reported) {
            b.securities_value = b.securities_value_reported;
          }

          if (b.total_value_reported) {
            b.total_value = b.total_value_reported;
          }

          this.enrichAccountData(b, account);
          return b;
        }).filter(d => d !== undefined);
      })
    )
  }

  fetchBalancesReport(startDate: string, endDate: string) {
    return combineLatest([
      this.fetchSourceBalancesReport(startDate, endDate),
      this.fetchLucaAccountBalancesReport(startDate, endDate)
    ]).pipe(
      map(([sourceBalances, manualBalances]) => {
        const balances = [...sourceBalances, ...manualBalances];

        return balances;
      })
    )
  }

  fetchPositionsReport(startDate: string, endDate: string) {
    return combineLatest([
      this.fetchSourcePositionsReport(startDate, endDate),
      this.fetchLucaAccountPositionsReport(startDate, endDate)
    ]).pipe(
      map(([sourcePositions, lucaPositions]) => {
        const balances = [...sourcePositions, ...lucaPositions];

        return balances;
      })
    )
  }

  // Needed for HDG and DST accounts
  private fetchLucaAccountPositionsReport(startDate: string, endDate: string) {
    const endpoint = `${this.baseEndpoint}/positions/filter`;

    return this.getDSTAndManualAccountIDs().pipe(
      switchMap((accountIDs) => {
        if (!accountIDs.length) {
          return of([])
        }

        const filter = this.buildManualAccountsFilter(accountIDs, startDate, endDate)

        return this.utilsService.fetchAllFilteredPages(endpoint, filter, 15000)
      }),
      map(positions => {
        const accountMap = this.accountService.accountsByAccountId;

        return positions.map(p => {
          const account = accountMap[p.account_id];

          if (!account) {
            return;
          }

          this.enrichAccountData(p, account);

          // OLD luca way commenting out here for reference
          p.reported_date = p.as_of_date;
          p.purchaseAmount = p.abs_open_units * (p.cost_basis_unit_price);
          p.price = p.cost_basis_unit_price;

          const direction = p.direction === 'L' ? 1 : -1;
          p.currentMarketValue = p.abs_open_units * p.appraised_unit_price * direction;

          return p;
        }).filter(d => d !== undefined);
      })
    );
  }

  fetchSourcePositionsReport(startDate: string, endDate: string) {
    const endpoint = `${this.sourceEndpoint}/lots/filter`;
    const dateFilter = this.getReportedDateFilter(startDate, endDate);

    return this.accountService.entitiesLoaded$.pipe(
      filter(loaded => loaded),
      switchMap(() => this.utilsService.fetchAllFilteredPages(endpoint, dateFilter, 10000)),
      switchMap((positions) => {
        const securityIDs = _uniq(positions.map(h => h.security_id)) as number[];

        return combineLatest([
          of(positions),
          this.securitiesService.fetchSecurities(securityIDs),
        ])
      }),
      map(([positions, securities]) => {
        const securityMap = securities.reduce((map, obj) => (map[obj.id] = obj, map), {});
        const accountMap = this.accountService.accountsByAccountId;

        return positions.map(p => {
          const account = accountMap[p.account_id];
          const security = securityMap[p.security_id];

          if (!account) {
            return;
          }

          this.enrichAccountData(p, account);
          this.enrichSecurityData(p, security);

          // TODO: Clarify these fields
          p.purchaseAmount = p.abs_cost_basis

          if (p.abs_cost_basis !== null) {
            p.price = p.abs_cost_basis / p.abs_current_units
          } else {
            p.price = null;
          }

          const direction = p.direction === 'L' ? 1 : -1;
          p.currentMarketValue = p.abs_current_value * direction;

          return p;
        })
      })
    )
  }

  // needed for DST and HDG
  fetchLucaAccountHoldingsReport(startDate: string, endDate: string) {
    const endpoint = `${this.baseEndpoint}/account-holdings/filter`;

    return this.getDSTAndManualAccountIDs().pipe(
      switchMap((accountIDs) => {
        if (!accountIDs.length) {
          return of([])
        }

        const filter = this.buildManualAccountsFilter(accountIDs, startDate, endDate)

        return this.utilsService.fetchAllFilteredPages(endpoint, filter, 15000)
      }),
      switchMap((holdings) => {
        const securityIDs = _uniq(holdings.map(h => h.security_id)) as number[];

        return combineLatest([
          of(holdings),
          this.securitiesService.fetchSecurities(securityIDs),
        ])
      }),
      map(([holdings, securities]) => {
        const securityMap = securities.reduce((map, obj) => (map[obj.id] = obj, map), {});
        const accountMap = this.accountService.accountsByAccountId;

        return holdings.map((h) => {
          const account = accountMap[h.account_id];
          const security = securityMap[h.security_id];

          if (!account) {
            return;
          }

          this.enrichAccountData(h, account);
          this.enrichSecurityData(h, security);

          // backwards compatibility
          h.reported_date = h.as_of_date;
          h.abs_cost_basis = h.abs_beginning_value;

          h.price = h.appraised_unit_price;
          const direction = h.direction === 'L' ? 1 : -1;
          h.currentMarketValue = h.abs_units * h.appraised_unit_price * direction;

          return h;
        }).filter(h => h !== undefined);
      })
    );
  }

  fetchSourceHoldingsReport(startDate: string, endDate: string) {
    const endpoint = `${this.sourceEndpoint}/positions/filter`;
    const dateFilter = this.getReportedDateFilter(startDate, endDate);

    return this.accountService.entitiesLoaded$.pipe(
      filter(loaded => loaded),
      switchMap(() => this.utilsService.fetchAllFilteredPages(endpoint, dateFilter, 20000)),
      switchMap((holdings) => {
        const securityIDs = _uniq(holdings.map(h => h.security_id)) as number[];

        return combineLatest([
          of(holdings),
          this.securitiesService.fetchSecurities(securityIDs),
        ])
      }),
      map(([holdings, securities]) => {
        const securityMap = securities.reduce((map, obj) => (map[obj.id] = obj, map), {});
        const accountMap = this.accountService.accountsByAccountId;

        return holdings.map((h) => {
          const account = accountMap[h.account_id];
          const security = securityMap[h.security_id];

          if (!account) {
            return;
          }

          this.enrichAccountData(h, account);
          this.enrichSecurityData(h, security);

          h.price = h.abs_value_unit_price;
          const direction = h.direction === 'L' ? 1 : -1;
          h.currentMarketValue = h.abs_value * direction;

          return h;
        }).filter(h => h !== undefined);
      })
    );
  }

  fetchHoldingsReport(startDate: string, endDate: string) {
    return combineLatest([
      this.fetchSourceHoldingsReport(startDate, endDate),
      this.fetchLucaAccountHoldingsReport(startDate, endDate)
    ]).pipe(
      map(([sourceHoldings, manualHoldings]) => {
        const holdings = [...sourceHoldings, ...manualHoldings];

        return holdings;
      })
    )
  }

  getTransactionCategory(category: string): string {
    switch(category) {
      case 'trd':
        return 'Trade';
      case 'xfr':
        return 'Transfer';
      case 'inc':
        return 'Income/Expense';
      case 'crp':
        return 'Corporate Action';
      case 'oth':
        return 'Other';
      default:
        return 'Other';
    }
  }

  getTransactionClassification(classification: string): string {
    switch (classification) {
      case 'bto':
        return 'Buy';
      case 'stc':
        return 'Sell';
      case 'btc':
        return 'Buy';
      case 'sto':
        return 'Sell';
      case 'dvr':
        return 'Dividend Reinvestment';
      case 'div':
        return 'Dividend';
      case 'inc':
        return 'Income';
      case 'exp':
        return 'Expense';
      case 'int':
        return 'Interest Payment';
      case 'mfe':
        return 'Management Fee';
      case 'tax':
        return 'Tax Fee';
      case 'dep':
        return 'Cash Deposit';
      case 'wth':
        return 'Cash Withdrawal';
      case 'rec':
        return 'Security Deposit';
      case 'del':
        return 'Security Withdrawal';
      case 'spl':
        return 'Split';
      case 'chg':
        return 'Symbol Change';
      case 'jnl':
        return 'Journal';
      default:
        return 'Other';
    }
  }

  fetchLucaTransactions(startDate: string, endDate: string) {

    return this.getDSTAndManualAccountIDs().pipe(
      switchMap((accountIDs) => {
        if (!accountIDs.length) {
          return of([[], [], []])
        }

        const filter = this.buildManualAccountsReportedDateFilter(accountIDs, startDate, endDate)

        return combineLatest([
          from(this.utilsService.fetchAllFilteredPages(`${this.baseEndpoint}/buy-sells/filter`, filter, 10000)),
          from(this.utilsService.fetchAllFilteredPages(`${this.baseEndpoint}/income-expense/filter`, filter, 10000)),
          from(this.utilsService.fetchAllFilteredPages(`${this.baseEndpoint}/transfers/filter`, filter, 10000)),
        ])
      }),
      switchMap(([buySells, incomeExpenses, transfers]) => {
        const transactions = [...buySells, ...incomeExpenses, ...transfers];

        const securityIDs = _uniq(transactions.map(h => {
          if (h.security_id && h.security_id != null) {
            return h.security_id;
          }
        }).filter(v=>v)) as number[];

        return combineLatest([
          of(transactions),
          this.securitiesService.fetchSecurities(securityIDs),
        ])
      }),
      map(([transactions, securities]) => {
        const securityMap = securities.reduce((map, obj) => (map[obj.id] = obj, map), {});
        const accountMap = this.accountService.accountsByAccountId;

        return transactions.map(t => {
          const account = accountMap[t.account_id];
          const security = securityMap[t.security_id];

          if (!account) {
            return;
          }

          this.enrichAccountData(t, account);
          this.enrichSecurityData(t, security);

          t.fees = t.transaction_fee;
          t.amount = t.abs_amount;
          t.custodian = t.source;

          if (t.object === 'data.luca.buy_sell') {
            t.units = t.abs_units
            t.price = t.abs_amount / t.abs_units;
            t.transactionCategory = 'Trade';

            if (t.type === 'BTO' || t.type === 'BTC') {
              t.transactionType = 'Buy';
            } else if (t.type === 'STO' || t.type === 'STC') {
              t.transactionType = 'Sell';
            }
          } else if (t.object === 'data.luca.income_expense') {
            t.transactionCategory = 'Income/Expense';

            for (const key in t.meta_data) {
              if (t.transactionType && t.transactionType !== '') {
                continue;
              }

              if (t.meta_data.hasOwnProperty(key) && t.meta_data[key]) {
                switch (key) {
                  case 'is_tax':
                    t.transactionType = 'Tax';
                    break;
                  case 'is_dividend':
                    t.transactionType = 'Dividend';
                    break;
                  case 'is_other_income':
                    t.transactionType = 'Other Income';
                    break;
                  case 'is_other_expense':
                    t.transactionType = 'Other Expense';
                    break;
                  case 'is_management_fee':
                    t.transactionType = 'Management Fee';
                    break;
                  case 'is_interest':
                    t.transactionType = 'Interest';
                    break;
                  default:
                    t.transactionType = '';
                    break;
                }
              }
            }
          } else if (t.object === 'data.luca.transfer') {
            t.units = t.abs_units
            t.price = t.abs_amount / t.abs_units;
            t.transactionCategory = 'Transfer';

            switch (t.type) {
              case 'DEP':
                t.transactionType = 'Cash Deposit';
                break;
              case 'WITH':
                t.transactionType = 'Cash Withdrawal';
                break;
              case 'TLO':
                t.transactionType = 'Security Deposit';
                break;
              case 'TLC':
                t.transactionType = 'Security Withdrawal';
                break;
              default:
                t.transactionType = '';
                break;
            }
          }


          return t;
        }).filter(d => d !== undefined);
      })
    )
  }

  fetchTransactionsReport(startDate: string, endDate: string) {

    return combineLatest([
      this.fetchLucaTransactions(startDate, endDate),
      this.fetchSourceTransactions(startDate, endDate),
    ]).pipe(
      map(([lucaTransactions, sourceTransactions]) => {
        return [...lucaTransactions, ...sourceTransactions];
      })
    )
  }

  fetchSourceTransactions(startDate: string, endDate: string) {
    const dateFilter = this.getReportedDateFilter(startDate, endDate);

    return this.accountService.entitiesLoaded$.pipe(
      filter(loaded => loaded),
      switchMap(() => {
        return this.utilsService.fetchAllFilteredPages(`${this.sourceEndpoint}/transactions/filter`, dateFilter, 10000)
      }),
      switchMap((transactions) => {
        const securityIDs = _uniq(transactions.map(h => {
          if (h.security_id && h.security_id != null) {
            return h.security_id;
          }
        }).filter(v=>v)) as number[];

        return combineLatest([
          of(transactions),
          this.securitiesService.fetchSecurities(securityIDs),
        ])
      }),
      map(([transactions, securities]) => {
        const securityMap = securities.reduce((map, obj) => (map[obj.id] = obj, map), {});
        const accountMap = this.accountService.accountsByAccountId;

        return transactions.map(t => {
          const account = accountMap[t.account_id];
          const security = securityMap[t.security_id];

          if (!account) {
            return;
          }

          this.enrichAccountData(t, account);
          this.enrichSecurityData(t, security);

          t.custodian = t.source;
          t.transactionCategory = this.getTransactionCategory(t.category);
          t.transactionType = this.getTransactionClassification(t.classification);

          return t;
        }).filter(d => d !== undefined);
      })
    )
  }

  fetchThirteenReport(startDate: string) {
    const endpoint = `${this.sourceEndpoint}/positions/filter`
    const dateFilter = this.getReportedDateFilter(startDate, null);

    return this.utilsService.fetchAllFilteredPages(endpoint, dateFilter, 15000)
      .pipe(
        switchMap((holdings) => {
          const securityIDs = holdings.map(h => h.security_id) as number[];

          return combineLatest([
            of(holdings),
            this.securitiesService.fetchSecurities(securityIDs),
          ]);
        }),
        map(([holdings, securities]) => {
          const thirteenReportData = [];

          const unitBySecId = {};
          const marketValueBySecId = {};
          const secIds = [];

          holdings.forEach(h => {
            if (!unitBySecId[h.security_id] && !marketValueBySecId[h.security_id]) {
              unitBySecId[h.security_id] = 0;
              marketValueBySecId[h.security_id] = 0;
              secIds.push(h.security_id);
            }

            unitBySecId[h.security_id] += parseFloat(h.abs_units);

            const direction = h.direction === 'L' ? 1 : -1;
            marketValueBySecId[h.security_id] += parseFloat(h.abs_units) * parseFloat(h.abs_value_unit_price) * direction;
          });

          secIds.forEach(sec => {
            const security = securities.find(s => s.id === sec);
            thirteenReportData.push({
              date: startDate,
              symbol: security.symbol,
              cusip: security.cusip,
              description: security.description,
              units: unitBySecId[sec],
              marketValue: marketValueBySecId[sec]
            });
          });

          return thirteenReportData;
        })
      );
  }

  fetchSecurityReport(startDate: string) {
    const endpoint = `${this.baseEndpoint}/fff-security-report`;

    const dateFilter = {
      date: startDate,
    }

    return this.accountService.entitiesLoaded$.pipe(
      filter(loaded => loaded),
      switchMap(() => {
        return this.httpClient.post(endpoint, dateFilter);
      }),
    )
  }

  private enrichAccountData(obj: any, account: Account): void {
    obj.accountNumber = account.number;
    obj.accountName = account.name;
    obj.accountType = account.acct_type;
    obj.custodian = account.custodian;
    obj.firm_id = account.firm_id;
    obj.advisor_code = account.advisor_code;
    obj.advisor_codes = account.advisor_codes;
    obj.firm_ids = account.firm_ids;
    obj.is_account = account.is_account;

    if (account.household_id) {
      const household = this.accountService.householdsByHouseholdId[account.household_id];

      obj.household = household ? household.name : null;
      obj.household_id = household ? household.id : null;
    }
  }

  private enrichSecurityData(obj: any, security: Security): void {
    obj.securitySymbol = security ? (security.symbol ? security.symbol : security.cusip) : null;
    obj.securityDesc = security ? security.description : null;
    obj.securityType = security ? security.security_type : null;
  }

  private getDateFilterConditions(startDate: string, endDate: string) {
    const filterConditions = [];

    if (startDate && !endDate) {
      filterConditions.push({
        value: startDate,
        op: 'eq',
      })
    } else {
      filterConditions.push(...[{
        value: startDate,
        op: 'gte',
      },
        {
          value: endDate,
          op: 'lte',
        }]);
    }

    return filterConditions
  }

  private getReportedDateFilter(startDate: string, endDate: string) {
    return {
      reported_date: {
        any_or_all: 'all',
        conditions: this.getDateFilterConditions(startDate, endDate)
      }
    };
  }

  private getAsOfDateFilter(startDate: string, endDate: string) {
    return {
      as_of_date: {
        any_or_all: 'all',
        conditions: this.getDateFilterConditions(startDate, endDate)
      }
    };
  }

  private buildManualAccountsReportedDateFilter(accountIDs, startDate, endDate) {
    const accountIdConditions = [];

    accountIDs.forEach(id => {
      accountIdConditions.push({
        value: id,
        op: 'eq'
      })
    })

    const filter = this.getReportedDateFilter(startDate, endDate);

    filter['account_id'] = {
      any_or_all: 'any',
      conditions: accountIdConditions
    };

    return filter;
  }

  private buildManualAccountsFilter(accountIDs, startDate, endDate) {
    const accountIdConditions = [];

    accountIDs.forEach(id => {
      accountIdConditions.push({
        value: id,
        op: 'eq'
      })
    })

    const filter = this.getAsOfDateFilter(startDate, endDate);

    filter['account_id'] = {
      any_or_all: 'any',
      conditions: accountIdConditions
    };

    return filter;
  }
}
