<div style="height: 100vh;">
  <app-navbar></app-navbar>
  <br *ngIf="!ingestionProgressAlert.isCollapsed">
  <br *ngIf="!ingestionProgressAlert.isCollapsed">
  <app-search *ngIf="isFirmUser() && isSearchVisible | async"></app-search>

  <div class="content-container">
    <div class="inner-content-container">
      <mat-sidenav-container class="example-container" autosize>
        <mat-sidenav-content>
          <div style="padding-top: 65px;">
            <div [hidden]="!resolvingRoute">

              <div class="loading">
                Loading...

                <div style="height: 10px;"></div>

                <app-material-spinner></app-material-spinner>
              </div>

            </div>

            <div [hidden]="resolvingRoute">
              <router-outlet></router-outlet>
            </div>
          </div>

          <app-support-link *ngIf="isFirmUser()"></app-support-link>
        </mat-sidenav-content>
      </mat-sidenav-container>
    </div>
  </div>
</div>
