import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AtlasInputComponent } from './atlas-input/atlas-input.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AtlasTabsModule} from './atlas-tabs/atlas-tabs.module';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AtlasDatePickerComponent } from './atlas-date-picker/atlas-date-picker.component';
import {MaterialModule} from '../material/material.module';
import { AtlasMultiselectComponent } from './atlas-multiselect/atlas-multiselect.component';
import { AtlasSubmitButtonComponent } from './atlas-submit-button/atlas-submit-button.component';
import { AtlasActivityCardComponent } from './atlas-activity-card/atlas-activity-card.component';
import { AtlasActivityCardHeaderComponent } from './atlas-activity-card/atlas-activity-card-header/atlas-activity-card-header.component';
import { AtlasActivityCardContentComponent } from './atlas-activity-card/atlas-activity-card-content/atlas-activity-card-content.component';
import { AtlasActivityCardDetailsComponent } from './atlas-activity-card/atlas-activity-card-details/atlas-activity-card-details.component';
import { AtlasMultiDatePickerComponent } from './atlas-multi-date-picker/atlas-multi-date-picker.component';
import { AtlasTimePeriodDropdownComponent } from './atlas-time-period-dropdown/atlas-time-period-dropdown.component';
import {ChartsModule} from 'ng2-charts';
import { AtlasFileUploadComponent } from './atlas-file-upload/atlas-file-upload.component';
import {ExcelService} from './excel.service';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [
    AtlasInputComponent,
    AtlasDatePickerComponent,
    AtlasMultiselectComponent,
    AtlasSubmitButtonComponent,
    AtlasActivityCardComponent,
    AtlasActivityCardHeaderComponent,
    AtlasActivityCardContentComponent,
    AtlasActivityCardDetailsComponent,
    AtlasMultiDatePickerComponent,
    AtlasTimePeriodDropdownComponent,
    AtlasFileUploadComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AtlasTabsModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatButtonModule,
    MaterialModule,
    ChartsModule,
    MatDialogModule,
  ],
  exports: [
    AtlasInputComponent,
    AtlasTabsModule,
    AtlasDatePickerComponent,
    AtlasMultiselectComponent,
    AtlasSubmitButtonComponent,
    AtlasActivityCardComponent,
    AtlasActivityCardContentComponent,
    AtlasActivityCardDetailsComponent,
    AtlasActivityCardHeaderComponent,
    AtlasMultiDatePickerComponent,
    AtlasTimePeriodDropdownComponent,
    ChartsModule,
    AtlasFileUploadComponent,
  ],
  providers: [
    ExcelService
  ]
})
export class AtlasUiModule { }
