<h2 class="left" mat-dialog-title>Need Help?</h2>

<button
    mat-icon-button
    class="right"
    [mat-dialog-close]="">
  <mat-icon>close</mat-icon>
</button>

<div mat-dialog-content style="width: 500px">
  <p>
    Head to our Support Portal to submit a request and we'll be more than happy to assist.
  </p>

  <p>
    For more information on submitting a support request check out our Support Documentation.
  </p>
</div>

<div mat-dialog-actions class="right">
  <a
      mat-flat-button
      color="primary"
      target="_blank"
      href="https://BridgeFT.Zendesk.com">
    Support Portal
  </a>

  <a
      mat-stroked-button
      color="primary"
      target="_blank"
      href="http://help.bridgeft.com/en/articles/7471548-bridgeft-support-portal-bridgeft-zendesk-com">
    Support Documentation
  </a>
</div>
