import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SupportDialogComponent } from '../support-dialog/support-dialog.component';

@Component({
  selector: 'app-support-link',
  templateUrl: './support-link.component.html',
  styleUrls: ['./support-link.component.scss']
})
export class SupportLinkComponent {
  constructor(
    private dialog: MatDialog,
  ) { }

  openDialog() {
    this.dialog.open(SupportDialogComponent);
  }
}
